<template>
  <div class="page">
    <v-list
      ref="list"
      :isAdd="true"
      :headers="headers"
      :tableUrl="tableUrl"
      :searchParam.sync="searchParam"
    >
      <template #headBtnSlot>
        <v-button text="新增" @click="toAdd"></v-button>
      </template>
      <template #searchSlot>
        <v-input label="公告标题" v-model="searchParam.topicTitle" />
        <v-select
          clearable
          :options="statusOps"
          v-model="searchParam.status"
          @change="$refs.list.search()"
          label="状态"
        />
        <v-date-picker
          label="创建时间"
          v-model="createTime"
          type="datetimerange"
          formatValue="yyyy-MM-dd HH:mm:ss"
          startPlaceholder="创建开始时间"
          endPlaceholder="创建结束时间"
        />
      </template>
      <template #operateSlot="scope">
        <v-button text="编辑" type="text" @click="toEdit(scope.row)" />
        <v-button text="删除" type="text" @click="deleteTopic(scope.row)" />
      </template>
    </v-list>
  </div>
</template>

<script>
import { statusMap, setStatusOps, isTopMap, setIsTopOps } from "./map.js";
import { getTopicListURL, deleteTopicUrl } from "./api.js";
export default {
  name: "propertyTopic",
  data() {
    return {
      searchParam: {
        status: undefined,
        topicTitle: "",
        topicType: 23,
        createTimeS: "",
        createTimeE: "",
        sortField: "NEW",
      },
      headers: [
        {
          prop: "topicTitle",
          label: "公告标题",
        },
        {
          prop: "createTime",
          label: "创建时间",
        },
        {
          prop: "isTop",
          label: "是否置顶",
          formatter: (row, prop) => {
            return isTopMap[row[prop]] || "--";
          },
        },
        {
          prop: "closePraise",
          label: "是否关闭点赞",
          formatter: (row, prop) => {
            return isTopMap[row[prop]] || "--";
          },
        },
        {
          prop: "closeComment",
          label: "是否关闭评论",
          formatter: (row, prop) => {
            return isTopMap[row[prop]] || "--";
          },
        },
        {
          prop: "status",
          label: "状态",
          formatter: (row, prop) => {
            return statusMap[row[prop]] || "--";
          },
        },
      ],
      tableUrl: getTopicListURL,
      isTopOps: setIsTopOps(),
      statusOps: setStatusOps(),
    };
  },
  computed: {
    createTime: {
      get() {
        return !this.searchParam.createTimeS && !this.searchParam.createTimeE
          ? []
          : [this.searchParam.createTimeS, this.searchParam.createTimeE];
      },
      set(val) {
        [this.searchParam.createTimeS, this.searchParam.createTimeE] = val || [
          "",
          "",
        ];
      },
    },
  },
  methods: {
    deleteTopic(item) {
      //删除居民超话
      this.$confirm("是否确定删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let params = {
          ids: item.id,
        };
        this.$axios
          .post(`${deleteTopicUrl}`, this.$qs.stringify(params))
          .then((res) => {
            if (res.code === 200) {
              this.$message.success("操作成功");
              this.$refs.list.search();
            } else {
            }
          });
      });
    },
    toAdd() {
      this.$router.push({
        name: "propertyTopicForm",
      });
    },
    toEdit(row) {
      this.$router.push({
        name: "propertyTopicForm",
        query: { id: row.id, communityId: row.communityId },
      });
    },
  },
};
</script>

<style scoped lang="less">
.page {
  box-sizing: border-box;
  height: 100%;
}
</style>
